import {getHRTempTypeName} from "../constant/template";
import {dealFlow} from "../js/apply";
import {getUrlFileName, isArray, getKeyTitle} from "../js/tool";
import {getNumberTypeForm} from "./form";

export const hrApplyStatus = [
    {key: 1, title: '审批中'},
    {key: 2, title: '已驳回'},
    {key: 3, title: '已撤销'},
    {key: 4, title: '已通过'},
]

export const assMessageStatus = [
    {key: 0, title: '待办'},
    {key: 1, title: '已办'},
]

export const getHRApplyStatusText = function (status) {
    return getKeyTitle(hrApplyStatus, status);
}

export const getAssMessageStatus = function (status) {
    return getKeyTitle(assMessageStatus, status);
}

export const dealFormDetail = function (form, detail, config) {
    let imgList = [];
    config.forEach(item => {
        let value = detail[item.key];
        let d = {
            key: item.key,
            label: item.label,
            component: item.component
        }
        switch (item.component) {
            case "a-select":
                if(item.props.options && item.props.options.length > 0) {
                    let opt = item.props.options[0];
                    if(typeof opt === 'object') {
                        value = getKeyTitle(item.props.options, value);
                    }
                }
                break;
            case "form-upload":
                // 上传值兼容处理
                if(value){
                    if (!isArray(value)) {
                        if(item.mediaType == 'mix') {
                            value = JSON.parse(value);
                        } else {
                            value = value.split(",").map(url => {
                                return {url};
                            });
                        }
                    }
                    value.forEach(f => {
                        f.fileName = getUrlFileName(f.url);
                        f.type = "image";
                        // f.sizeText = getFileSizeText(f.size);
                        imgList.push({
                            url: f.url,
                            title: `${item.label} ${f.fileName}`
                        })
                    });

                }
                d.component = 'upload';
                break;
            case "select-book":
                if(item.props.type == 'user' || item.props.type == 'dept') {
                    value = value.map(b => {return {id: b, type: item.props.type == 'dept' ? 'department' : 'user'}});
                }
                d.component = 'book';
        }
        d.value = value;
        form.push(d);
    });
    return {imgList};
}

export const dealHRApply = function (apply) {
    apply.tempName = apply.applyTemp?.name;
    apply.statusText = getHRApplyStatusText(apply.status);
    let type = apply.applyTemp.type;
    apply.typeText = getHRTempTypeName(type);
    let form = [{
        label: "提交时间",
        value: apply.create_time
    }];
    if (apply.detail) {
        try {
            let detail = JSON.parse(apply.detail);
            let config = getNumberTypeForm(type);
            let {imgList} = dealFormDetail(form, detail, config);
            apply.imgList = imgList;
        } catch (e) {
            console.error(e);
        }
    }
    apply.form = form;
    dealFlow(apply);
}

export const getApplyMsgTitle = function(type) {
    if(type) {
        const ti = ['进单公示', null, '入职公示', null, '调岗公示'][type - 1];
        const text = ['进单人员', null, '入职人员', null, '调岗人员'][type - 1];
        return `【${ti}】关于${text}的公示！`
    } else {
        return '人员变动公示'
    }
};
